<template>
    <div id="app" :class="[dark == true ? 'dark' : '' ]">
        <div class="loginOut" v-if="notLogin == false">
            <img :src="dark ? logoDark : logo " alt="">
            <img :src="dark ? loginOutDark : loginOut " @click="quick" alt="">
        </div>
        <div class="left" v-if="notLogin == false">
            <div class="top">
                <div class="tips">
                    <div class="center">
                        <div class="info">
                            <div class="timeType">
                                <p>{{ data.newest_blood_sugar.create_time_str}}<span :class="[ data.newest_blood_sugar.length == 0 ? 'normal' : (data.newest_blood_sugar.blood_sugar_status == 'normal' ? 'normal' : ( data.newest_blood_sugar.blood_sugar_status == 'high' ? 'high' : 'low' ) ) ]">{{ data.newest_blood_sugar.length == 0 ? '' : ( data.newest_blood_sugar.blood_sugar_status == 'normal' ? '达标' : ( data.newest_blood_sugar.blood_sugar_status == 'high' ? '偏高' : '偏低' ) ) }}</span></p>
                            </div>
                            <div class="nums">
                                <h4>
                                    {{ data.newest_blood_sugar.blood_sugar }}
                                </h4>
                                <div class="unit" v-if="data.newest_blood_sugar.length != 0">
                                    <img :src="dark ? trendDark : trend " :style="{'transform': data.newest_blood_sugar.trend == '1' ? 'rotate(90deg)' : data.newest_blood_sugar.trend == '5' ? 'rotate(45deg)' : data.newest_blood_sugar.trend == '20' ? 'rotate(180deg)' : data.newest_blood_sugar.trend == '10' ? 'rotate(135deg)' : 'rotate(0deg)' }" alt="">
                                    <p>mmol/L</p>
                                </div>
                            </div>
                        </div>
                        <div class="tir">
                            <div class="tirChart">
                                <div class="vhigh" :style="{ 'height':data.newest_blood_sugar.length == 0 ? '0%' : ((Number(data.tir.very_high) * 100) + '%') }"></div>
                                <div class="high" :style="{'height':data.newest_blood_sugar.length == 0 ? '0%' : ((Number(data.tir.high) * 100) + '%') }"></div>
                                <div class="normal" :style="{ 'height' : data.newest_blood_sugar.length == 0 ? '100%' : ( (Number(data.tir.common) * 100) + '%') }"></div>
                                <div class="low" :style="{ 'height' : data.newest_blood_sugar.length == 0 ? '0%' : ( ( Number(data.tir.low) * 100) + '%') }"></div>
                                <div class="vlow" :style="{ 'height' : data.newest_blood_sugar.length == 0 ? '0%' : ((Number(data.tir.very_low) * 100) + '%' ) }"></div>
                            </div>

                            <div class="tirInfo">
                                <p>{{ data.newest_blood_sugar.length == 0 ? '--' : (Number(data.tir.very_high).toFixed(2) * 100).toFixed(0) }}% 非常高</p>
                                <p>{{ data.newest_blood_sugar.length == 0 ? '--' : (Number(data.tir.high).toFixed(2) * 100).toFixed(0) }}% 高</p>
                                <p>{{ data.newest_blood_sugar.length == 0 ? '--' : (Number(data.tir.common).toFixed(2) * 100).toFixed(0) }}% 正常</p>
                                <p>{{ data.newest_blood_sugar.length == 0 ? '--' : (Number(data.tir.low).toFixed(2) * 100).toFixed(0) }}% 低</p>
                                <p>{{ data.newest_blood_sugar.length == 0 ? '--' : (Number(data.tir.very_low).toFixed(2) * 100).toFixed(0) }}% 非常低</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="head">
                    <div class="center">
                        <img :src="userInfo.avatar" alt="">
                        <p>{{ userInfo.nickname}}</p>
                    </div>
                </div>
            </div>
            <div class="charts" v-if="value != null">
                <ChartLine :value="value" :dark="dark" />
            </div>
        </div>
        <div class="right" v-if="notLogin == false">
            <div class="dataList" v-for="(item,index) in dataList" :key="index" v-if="item.value != ''">
                <div class="list">
                    <p>{{ item.time }}</p>
                    <div class="value">
                        <p>{{ item.value != '' ? item.value : '--' }}</p>
                        <img v-if="item.value != ''" :src="dark ? trendDark : trend " :style="{'transform': data.newest_blood_sugar.trend == '1' ? 'rotate(90deg)' : data.newest_blood_sugar.trend == '5' ? 'rotate(45deg)' : data.newest_blood_sugar.trend == '20' ? 'rotate(180deg)' : data.newest_blood_sugar.trend == '10' ? 'rotate(135deg)' : 'rotate(0deg)' }" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="privacy" v-if="notLogin == true">
            <div class="content">
                <div class="title">
                    <p>糖动隐私使用说明</p>
                </div>
                <div class="contents">
                    <p>糖动隐私使用说明亲爱的用户，感谢您信任并使用糖动！我们非常重视您的个人信息和隐私保护。为了更好地保障您的个人权益在使用我们的产品前，请务必审慎阅读<a @click="webview(1)" style="color: #16CCA6;">《用户协议》</a>和<a @click="webview" style="color: #16CCA6;">《隐私政策》</a>，了解更多隐私相关协议。</p>
                </div>
            </div>
            <div class="button">
                <div class="true" @click="goLogin">
                    <p>同意并使用</p>
                </div>
                <div class="out" @click="outApp">
                    <p>退出</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { MustPrams } from '@/assets/js/Cache'
    import { getUserInfo, getData } from '@/Api/request.api';
    import ChartLine from '@/components/ChartLine.vue'

    export default {
        components:{
            ChartLine
        },
        data(){
            return{
                notLogin: false,
                userInfo: null,
                data: null,
                dark: false,
                trend: require('@/assets/img/icon.png'),
                trendDark: require('@/assets/img/icon_dark.png'),
                logo: require('@/assets/img/logo.png'),
                logoDark: require('@/assets/img/logo_dark.png'),
                loginOut: require('@/assets/img/loginOut.png'),
                loginOutDark: require('@/assets/img/loginOut_dark.png'),
                value: null,
                dataList: null,
            }
        },
        methods:{
            getUserInfo(phone){
                getUserInfo({
                    mobile: phone,
                    verify_code: '2046'
                }).then( res =>{
                    if(res.data.code == 100000){
                        this.userInfo = res.data.data;
                    }
                })
            },

            getData(token){
                MustPrams(token).then ( res =>{
                    getData(res).then( res =>{
                        if(res.data.code == 100000){
                            this.data = res.data.data;
                            this.value = {
                                data: res.data.data.data,
                                target_low: res.data.data.target_low,
                                target_high: res.data.data.target_high
                            }
                            const data = res.data.data.data;
                            this.dataList = [...data].sort((a, b) => {
                                if (a.time < b.time) {
                                    return 1;
                                } else if (a.time > b.time) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });
                        }
                    })
                })
            },

            quick(){
                window.system.postMessage('login out');
            },

            goLogin(){
                window.system.postMessage('to login');
            },

            outApp(){
                window.system.postMessage('quit');
            },

            webview(){
                window.system.postMessage(JSON.stringify({type: 1}));
            },

            getUrlKey(name){
                return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
            }
        },
        created(){
            console.log();
            let phone = this.getUrlKey('phone'),
                dark = this.getUrlKey('dark'),
                token = this.getUrlKey('token');
            this.dark = dark == 0 || !dark ? false : true;
            if(token != '' && token != null && token != 'null'){
                this.token = token;
                this.getUserInfo(phone);
                this.getData(token);
                setTimeout(() =>{
                    this.getData(token);
                },120000)
            }else{
                this.notLogin = true;
            }

        }
    }
</script>

<style>

    body,html,
    #app{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        background: #f4f4f4;
    }

    #app.dark{
        background-color: #121212;
    }

    *{
        margin: 0;
        padding: 0px;
        box-sizing: border-box;
    }

    #app{
        display: flex;
        flex-wrap: wrap;
    }

    #app.dark .loginOut{
        border-color: #1F1F1F;
    }

    .loginOut{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 40px;
        border-bottom: 3px solid #ECECEC;
    }

    .loginOut img:nth-child(1){
        width: 235px;
        height: 40px;
        object-fit: cover;
    }

    .loginOut img:nth-child(2){
        width: 40px;
        height: 40px;
        cursor: pointer;
    }

    #app.dark .left{
        border-color: #1F1F1F;
    }

    .left{
        width: 70%;
        height: 90%;
        padding-top: 20px;
        padding-bottom: 40px;
        padding-left: 40px;
        border-right: 3px solid #ECECEC;
    }

    .left .top{
        display: flex;
        height: 40%;
    }

    #app.dark .tips{
        background: #1E1E1E;
    }

    .tips{
        display: flex;
        align-items: center;
        background: white;
        border-radius: 10px;
        width: 70%;
        height: 100%;
        padding: 20px 40px;
    }

    .tips .center{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    #app.dark .timeType p{
        color: #c1c1c1;
    }

    .tips .info .timeType{
        margin-top: 20px;
        font-size: 20px;
    }

    .tips .info .timeType span{
        margin-left: 5px;
        color: #16CCA6;
        font-size: 20px;
    }


    .tips .info .timeType span.high{
        color: #FFAA29;
    }

    .tips .info .timeType span.low{
        color: #FF6E6E;
    }

    .tips .info .nums{
        margin-top: 25px;
        display: flex;
    }

    #app.dark .tips .info .nums h4{
        color: #eaeaea;
    }

    .tips .info .nums h4{
        font-size: 65px;
        font-weight: bolder;
    }

    .tips .info .nums .unit{
        margin-left: 25px;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .tips .info .nums .unit img{
        width: 40px;
        height: 40px;
    }

    .tips .info .nums .unit p{
        font-size: 25px;
        margin-top: -15px;
        color: #AAAAAA;
    }

    .tips .tir{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tips .tir .tirChart{
        height: 125px;
        margin-top: -20px;
    }

    .tips .tir .tirChart>div{
        min-height: 2px;
        width: 55px;
        margin-top: 2px;
        border-radius: 2px;
    }

    .tips .tir .tirChart .vhigh{
        background-color: #FFB700;
    }

    .tips .tir .tirChart .high{
        background-color: #FFE452;
    }

    .tips .tir .tirChart .normal{
        background-color: #26D1B5;
    }

    .tips .tir .tirChart .low{
        background-color: #FF6E6E;
    }

    .tips .tir .tirChart .vlow{
        background-color: #EB3F3F;
    }

    .tips .tir .tirInfo{
        margin-top: 2px;
        margin-left: 15px;
        color: #868686;
    }

    .tips .tir .tirInfo p:nth-of-type(1){
        font-size: 16px;
    }

    .tips .tir .tirInfo p:nth-of-type(2){
        font-size: 18px;
    }

    .tips .tir .tirInfo p:nth-of-type(3){
        font-size: 22px;
    }

    .tips .tir .tirInfo p:nth-of-type(4){
        font-size: 18px;
    }

    .tips .tir .tirInfo p:nth-of-type(5){
        font-size: 16px;
    }

    #app.dark .head{
        background-color: #1E1E1E;
    }

    .left .top .head{
        width: 25%;
        height: 100%;
        background-color: white;
        margin-left: 2.5%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .left .top .head img{
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
    }

    #app.dark .head p{
        color: #A7A7A7;
    }

    .left .top .head p{
        font-size: 24px;
        width: 100%;
        text-align: center;
        color: #868686;
    }

    #app.dark .charts{
        background-color: #1E1E1E;
    }

    .charts{
        width: 97.5%;
        margin-top: 20px;
        height: 60%;
        background-color: #fff;
        padding: 20px 40px;
        padding-top: 0px;
        border-radius: 10px;
    }

    .charts .dom{
        height: 100%;
    }

    .right{
        width: 30%;
        height: 90%;
        overflow-y: scroll;
       padding-left: 2%;
       padding-right: 40px;
       padding-top: 20px;
    }

    .right::-webkit-scrollbar {
       display: none;
    }

    .right .dataList{
        margin-top: 10px;
    }

    .right .dataList:nth-of-type(1){
        margin-top: 0px;
    }

    #app.dark .right .list{
        background-color: #1E1E1E;
    }

    .right .dataList .list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
    }

    #app.dark .right .dataList .list>p{
        color: #7d7d7d;
    }

    .right .dataList .list>p{
        font-size: 22px;
        color: #868686;
    }

    .right .dataList .list .value{
        display: flex;
        align-items: center;
    }

    #app.dark .right .dataList .list .value p{
        color: #eaeaea;
    }

    .right .dataList .list .value p{
        font-weight: bold;
        font-size: 28px;
    }

    .right .dataList .list img{
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

    .privacy{
        position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0,0,0,.5);
		z-index: 99999;
        flex-wrap: wrap;
    }

    .privacy .content{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 250px;
        background-color: white;
        flex-direction: column;
        padding-top: 50px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
    }

    .privacy .content .title{
        text-align: center;
        font-size: 28px;
        font-weight: bold;
    }

    .privacy .content .contents{
        padding: 0px 40px;
        margin-top: 20px;
        line-height: 25px;
    }

    .privacy .button{
        position: absolute;
        bottom: -120px;
        left: 0px;
        width: 100%;
        height: 180px;
        display: flex;
        justify-content: space-around;
    }

    .privacy .button .true{
        width: 300px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #16CCA6;
        border-radius: 50px;
        color: white;
    }

    .privacy .button .out{
        width: 300px;
        height: 45px;
        line-height: 45px;
        color: #109c7d;
    }

</style>
