import axios from './request.create'
import api from './Api'

// 登录
export const getUserInfo = (data) => {
    return axios.request({
        url: api.getUserInfo,
        isLogin: false,
        data,
        method: 'post',
    })
}

// 获取用户信息
export const getData = (data) => {
    return axios.request({
        url: api.getData,
        isLogin: false,
        data,
        method: 'post',
    })
}