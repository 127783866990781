import md5 from 'js-md5';
/**
 * @description 合并参数 on(element, event, handler)
 */
export const MustPrams = (token) =>{
	return new Promise((resolve, rejects) => {
		let uuid = 'uuid',
			timestamp = new Date().getTime(),
			salt = 'yja2019_fcz',
			formData,
			params;
			formData = {
				uuid,
				token,
				timestamp,
				sign: md5(`${salt}${timestamp}${token}${uuid}`)
			};
			params = formData;
			resolve(params)
			rejects(null);
	})
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
    if (document.addEventListener) {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.addEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event && handler) {
                element.attachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
    if (document.removeEventListener) {
        return function (element, event, handler) {
            if (element && event) {
                element.removeEventListener(event, handler, false)
            }
        }
    } else {
        return function (element, event, handler) {
            if (element && event) {
                element.detachEvent('on' + event, handler)
            }
        }
    }
})()