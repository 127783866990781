<template>
    <div class="dom" v-if="value" ref="dom">
        
    </div>
</template>

<script>
    import echarts from "echarts";
    import tdTheme from "./theme.json";
    import { on, off } from "../assets/js/Cache";
    echarts.registerTheme("tdTheme", tdTheme);
    export default {
        props:{
            value: Object,
            dark: Boolean
        },
        watch:{
            'value':{
                handler(val){
                    this.init(val);
                },
                deep: true,
            }
        },
        data(){
            return{
                dom: null,
            }
        },
        methods:{
            hexToRgba(hex, opacity) {
                let rgbaColor = "";
                let reg = /^#[\da-f]{6}$/i;
                if (reg.test(hex)) {
                    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
                    "0x" + hex.slice(3, 5)
                    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
                }
                return rgbaColor;
            },
            init(res){
                let __self__ = this;
                let color = ["#16CCA6", "#00CA69"],
                    xAxisData = res.data.map(item => {
                        return item.time;
                    }),
                    yAxisData1 = res.data.map((item) => {
                        return item.value != '' ? Number(item.value) : '';
                    });
                let option = {
                    color: color,
                    tooltip: {
                        trigger: "axis",
                    },
                    animation: false,
                    visualMap: {
                        top: 0,
                        left: 0,
                        precision: 1,
                        show: false,
                        orient: 'horizontal',
                        pieces: [{
                                gte: 0,
                                lte: Number(res.target_low),
                                color: '#ED1B24'
                            },
                            {
                                gte: Number(res.target_low),
                                lte: Number(res.target_high),
                                color: '#16cca6'
                            },
                            {
                                gte: Number(res.target_high),
                                color: '#FFA901'
                            }
                        ],
                    },
                    grid: {
                        top: 40,
                        bottom: 40,
                        left: 22,
                        right: 32,
                    },
                    xAxis: [{
                        show: true,
                        type: "category",
                        offset: -1,
                        boundaryGap: false,
                        axisLabel: {
                            interval: 15,
                            formatter: '{value}',
                            margin: 20,
                            textStyle: {
                                color: __self__.dark ? '#c1c1c1' : "#333",
                                fontSize: 16,
                            }
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: "dashed",
                                color: "#E9E9E9"
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                    color: __self__.dark ? '#c1c1c1' : "#D9D9D9"
                            }
                        },
                        data: xAxisData
                    }],
                    yAxis: [{
                        show: true,
                        position: "right",
                        offset: -4,
                        type: "value",
                        interval: 4,
                        min: 0,
                        max(value) {
                            const val = (parseInt(value.max) + (4 - parseInt(value.max) % 4)) > 16 ? parseInt(value.max) + (4 - parseInt(value.max) % 4) : 16;
                            return val;
                        },

                        axisLabel: {
                            margin: 20,
                            textStyle: {
                                color: __self__.dark ? '#c1c1c1' : "#666",
                                fontSize: 16
                            }
                        },
                        nameTextStyle: {
                            color: "#666",
                            fontSize: 10,
                            lineHeight: 40
                        },
                        // 分割线
                        splitLine: {
                            lineStyle: {
                                color: __self__.dark && !__self__.isPDF ? '#2c2c2e' : "#E9E9E9"
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }],
                    series: [{
                        name: "血糖",
                        type: "line",
                        smooth: true,
                        connectNulls: true,
                        symbolSize: 2,
                        zlevel: 3,
                        lineStyle:{
                            width: 3, //折线宽度
                        },
                        symbol: "circle", // 数据交叉点样式
                        markArea: {
                            itemStyle: {
                                color: __self__.hexToRgba('#5EFF72', 0.1)
                            },
                            data: [
                                [{
                                    yAxis: res.target_low || 0
                                }, {
                                    yAxis: res.target_high || 0
                                }]
                            ],
                            animation: false
                        },
                        data: yAxisData1
                    }]
                };
                __self__.dom = echarts.init(__self__.$refs.dom, "tdTheme");
                __self__.dom.setOption(option);
                on(window, "resize", __self__.resize);
            }
        },
        mounted(){
            this.isReady = true;
            setTimeout( () => {
                this.init(this.value)
            },200)
        },
        beforeDestroy() {
            off(window, "resize", this.resize);
        }
    }
</script>

<style>
    .dom{
        width: 100%;
        height: 285px;
    }
</style>